import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import twitter from "./../../assets/icons/twitter.png";
import emailIcon from "./../../assets/icons/emailIcon.png";
import eyeIcon from "./../../assets/icons/eyeIcon.png";
import american from "./../../assets/images/americanMade.png";

import Input from "../shared/Input";
import PasswordInput from "../shared/PasswordInput";

import instance from "../../utils/axios";
import { API } from "../../utils/apiendpoints";
import { app } from "../../utils/firebase";
import { toast } from "react-toastify";

import { getAuth, signInWithPopup, TwitterAuthProvider } from "firebase/auth";

const twitterProvider = new TwitterAuthProvider();
const auth = getAuth(app);

const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange", reValidateMode: "onChange" });
  const [loader, setLoader] = useState(false);

  const onFormSubmit = (data) => {
    setLoader(true);
    instance
      .post(API.loginUser, data)
      .then((res) => {
        if (!res?.error) {
          setLoader(false);
          localStorage.setItem("accessToken", res.data?.data?.accessToken);
          navigate("/thankyou");
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response.data.message === "You are not verified. Kindly verify your account to login.") {
          navigate(`/signup?email=${data.email}&step=3`);
        }
      });
  };

  const verifySocialAccount = async (userData, email) => {
    await instance
      .post(`${API.authOAuthCheck}`, {
        email,
        token: userData.accessToken,
      })
      .then((res) => {
        if (res?.data?.status) {
          if (res?.data?.data === null) {
            toast.error(res?.data?.message);
          }
          if (res?.data?.data) {
            localStorage.setItem("accessToken", res.data?.data?.accessToken);
            // router.push(`/dashboard`);
            navigate("/thankyou");
          } else {
            if (userData.email) {
              // router.push(
              //   `/auth/signup/signupdetails?email=${userData.email}&socialToken=${userData.accessToken}&provider_name=${provider_name}`
              // );
              navigate("/thankyou");
            } else {
              // router.push(
              //   `/auth/signup/signupdetails?&socialToken=${userData.accessToken}&provider_name=${provider_name}`
              // );
              navigate("/thankyou");
            }
          }
        }
      })
      .catch((err) => {
        if (err?.response?.data?.status === false) {
          navigate(`/signup?googleEmail=${userData?.email}`);
        }
        console.log(err);
      });
  };

  const signinwithTwitter = async () => {
    setLoader(true);
    signInWithPopup(auth, twitterProvider)
      .then((result) => {
        if (result._tokenResponse.email === undefined || result._tokenResponse.email === null) {
          return toast.error("Please add email in your twitter account and try again");
        }
        setLoader(false);
        verifySocialAccount(result.user, result._tokenResponse.email);
      }).catch((error) => {
        setLoader(false);
        console.log(error)
      })
  }

  return (
    <>
      <div className="social-group">
        <div className="social-signup-btn" onClick={signinwithTwitter}>
          <img src={twitter} alt="Twitter" width={25} height={25} /> <span>Continue with Twitter</span>
        </div>
      </div>

      {/* format */}
      <div
        style={{
          width: "100%",
          height: 20,
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 20,
          display: "inline-flex",
        }}
      >
        <div
          style={{
            flex: "1 1 0",
            height: 1,
            background: "rgba(108.11, 113.95, 116.88, 0.50)",
          }}
        />
        <div
          style={{
            color: "rgba(108.11, 113.95, 116.88, 0.50)",
            fontSize: 12,
            fontFamily: "Poppins",
            fontWeight: "500",
            lineHeight: 20,
            wordWrap: "break-word",
          }}
        >
          OR
        </div>
        <div
          style={{
            flex: "1 1 0",
            height: 1,
            background: "rgba(108.11, 113.95, 116.88, 0.50)",
          }}
        />
      </div>
      {/* format */}

      <form onSubmit={handleSubmit(onFormSubmit)} className="form-fields mt-32">
        <div className="w-100 mb-18 spacingErr">
          <Input
            name="email"
            placeholder="Enter Your Email address"
            icon={emailIcon}
            errors={errors}
            register={register("email", {
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Please enter a valid Email",
              },
              required: {
                value: true,
                message: "This is required",
              },
            })}
          />
        </div>
        <div className="w-100 mb-18 spacingErr">
          <PasswordInput
            name="password"
            placeholder="Enter Your Password"
            icon={eyeIcon}
            errors={errors}
            register={register("password", {
              required: {
                value: true,
                message: "This is required",
              },
              pattern: {
                value:
                  // eslint-disable-next-line no-useless-escape
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_\-\+=:;"'?\/>.<,{}\[\]])[a-zA-Z\d~`!@#$%^&*()_\-\+=:;"'?\/>.<,{}\[\]]{8,}$/,
                message:
                  "Password must be 8 characters and must contain atleast 1 small alphabet, 1 capital alphabet, 1 numeric value and 1 special character",
              },
              minLength: {
                value: 8,
                message: "Password should contain minimum 8 characters long",
              },
              maxLength: {
                value: 20,
                message: "Password should contain maximum 20 characters long",
              },
            })}
          />
        </div>

        {/* <a href="/reset-password" className="custom-a cursor-pointer">
          Forget Password?
        </a> */}
        <Button
          className="custom-btn mb-32"
          // style={{ marginTop: "24px" }}
          variant="primary"
          type="submit"
        >
          {loader ? (
            <>
              <span
                className="spinner-border me-2 spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </>
          ) : (
            "Login with lifeID"
          )}
        </Button>
        <img className="bottom-img" src={american} alt="american_icon" />
      </form>
    </>
  );
};

export default Login;
