import React, { useState } from "react";
import { AiFillEye } from 'react-icons/ai'

const PasswordInput = ({ icon, name, placeholder, register, errors }) => {

  const [passwordType, setpasswordType] = useState('password');

  const handleShowPassword = () => {
    passwordType === 'password' ? setpasswordType('text') : setpasswordType('password');
  }
  return (
   <>
     <div className="common-input mb-5 position-relative">
      <input type={passwordType} name={name} placeholder={placeholder} {...register} className={`${errors[name] ? 'error-border' : 'normal-border'}`} />
      <span className="position-absolute" onClick={handleShowPassword}>
        {
          passwordType === 'text' ?
            <AiFillEye className="open-eye" />
            :
            <img width="18px" height="18px" src={icon} alt="icon" />
        }
      </span>
   
       <div>
       {errors[name] && <p class={`error-text mt-1`}>{errors[name]?.message}</p>}
       </div>
    </div>
   </>
  );
};

export default PasswordInput;
