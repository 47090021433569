import React from "react";

const Input = ({
  icon,
  name,
  disabled = false,
  placeholder,
  register,

  errors,
}) => {
  return (
    <div className="common-input  my-2 position-relative">
      <input
        type="text"
        disabled={disabled}
        name={name}
        placeholder={placeholder}
        {...register}
        className={`${errors[name]?.type ? "error-border" : "normal-border"} ${disabled ? "disabled-fields" : ""}`}
      />
      <span className="position-absolute">
        <img width="18px" height="14px" src={icon} alt="icon" />
      </span>
      {errors[name]?.type && (
        <p class={`error-text `}>{errors[name]?.message}</p>
      )}
    </div>
  );
};

export default Input;
