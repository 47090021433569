import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages";
import LoginPage from "./pages/login";
import ForgotPassword from "./pages/forgotPassword";
import ThankyouPage from "./pages/thankyou";
import SignupPage from "./pages/signup";
import DashboardPage from "./pages/dashboard";
import ProtectedRoutes from "./components/shared/ProtectedRoutes";
import { jwtDecode } from "jwt-decode";

function App() {
  const isAuthenticated = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      return false;
    }
    try {

      let decodedToken = jwtDecode(accessToken);
      let currentDate = new Date();
      // JWT exp is in seconds
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      // If there's an error in decoding, return false
      return false;
    }
  };
  return (
    <Router>
      <Routes>
        <Route element={<ProtectedRoutes isAuthenticated={isAuthenticated} isPublic />}>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/reset-password" element={<ForgotPassword />} />
          <Route path="/signup" element={<SignupPage />} />
        </Route>
        <Route element={<ProtectedRoutes isAuthenticated={isAuthenticated} />}>
          <Route element={<ThankyouPage />} path="/thankyou" exact />
          <Route element={<DashboardPage />} path="/dashboard" />
        </Route>
      </Routes>
    </Router >
  );
}

export default App;
