import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import dSocialBack from "./../../assets/icons/dSocialBack.png";
import lifeMistry from "./../../assets/images/lifeMistry.png";
import life from "./../../assets/icons/lifeGroup.png";
import { useNavigate } from "react-router-dom";


const DashboardPage = () => {

    const navigate = useNavigate();


    const handleLogout = () => {
        localStorage.removeItem("accessToken");
        navigate("/login");
    }
    return (
        <Container fluid className="vh-100 login-wrapper wrapper">
            <Row className="h-100">
                <Col xl={4} lg={5} md={6} className="left-col d-none d-md-block">
                    <div className="w-50 mx-auto text-center left-inner-block d-flex flex-column align-items-center justify-content-end h-100">
                        <img className="img-social-back" src={dSocialBack} alt="back" />
                        <h1>Here is your voice back</h1>
                        <div className="mistry-block">
                            <img src={lifeMistry} alt="life_mistry" />
                        </div>
                    </div>
                </Col>
                <Col xl={8} lg={7} md={6} className="right-col">
                    <Container className="h-100">
                        <Row className="h-100">
                            <Col lg={{ span: 6, offset: 1 }} className="d-flex">
                                <div className="w-100">
                                    <div className='d-flex justify-content-between align-items-center mt-3 mb-3'>
                                        <div className="icon-block">
                                            <img src={life} alt="life" />
                                        </div>
                                        <button className="btn btn-danger btn-lg btn-block btn-login"
                                            onClick={handleLogout}
                                        >Logout</button>
                                    </div>
                                    <h2>Welcome to l1fe Dashboard</h2>
                                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus, sit. Eveniet repudiandae numquam et facilis unde eligendi laboriosam, debitis ducimus quisquam dolorum provident eaque? Ut, veniam dolorem quod impedit architecto numquam sunt consequuntur labore minus nemo illum tempore, voluptatem alias ipsum beatae accusamus minima amet excepturi rerum. Dolores illo quisquam consequatur dolore repellat repudiandae voluptas nostrum molestiae provident nesciunt ea voluptatem, debitis ullam eveniet accusantium rerum labore dolorum ab consectetur ad eaque mollitia nemo! Totam, distinctio? Consequuntur libero, qui soluta, sequi similique facere sapiente, perspiciatis ipsum quibusdam officiis animi debitis voluptates deleniti unde? Autem obcaecati labore molestiae. Vero ipsum iste quaerat aliquam, incidunt similique repellendus accusantium quos praesentium magnam sit aut harum quod tempora iure obcaecati quo temporibus, soluta dolor! Autem, vero!</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    )
}

export default DashboardPage;