import { useEffect, useState } from "react";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import Step5 from "./steps/Step5";
import american from "./../../assets/images/americanMade.png";

const SignUp = () => {
  const searchParams = new URLSearchParams(document.location.search)
  const initialstep = searchParams.get("step");
  const [step, setStep] = useState(1);
  //redirection only for step 3
  useEffect(() => {
    if (initialstep === '3') {
      setStep(3);
    }
  }, [])

  if (step === 1) {
    return (
      <Step1 setStep={setStep}>
        <div className="aggree-consent mb-32">
          <p className="text-center">
            <div> By creating a l1feID, you agree to our </div>{" "}
            <span className="terms-color">Terms of Service </span> and{" "}
            <span className="terms-color">Privacy & Cookie Statement.</span>
          </p>
        </div>
        <img className="bottom-img" src={american} alt="american_icon" />
      </Step1>
    );
  } else if (step === 2) {
    return (
      <Step2 setStep={setStep}>
        <div className="aggree-consent mb-32">
          <p className="text-center">
            <div> By creating a l1feID, you agree to our </div>{" "}
            <span className="terms-color">Terms of Service </span> and{" "}
            <span className="terms-color">Privacy & Cookie Statement.</span>
          </p>
        </div>

        <img className="bottom-img" src={american} alt="american_icon" />
      </Step2>
    );
  } else if (step === 3) {
    return (
      <Step3 setStep={setStep}>
        <div className="aggree-consent mb-32">
          <p className="text-center">
            <div> By creating a l1feID, you agree to our </div>{" "}
            <span className="terms-color">Terms of Service </span> and{" "}
            <span className="terms-color">Privacy & Cookie Statement.</span>
          </p>
        </div>

        <img className="bottom-img" src={american} alt="american_icon" />
      </Step3>
    );
  } else if (step === 4) {
    return (
      <Step4 setStep={setStep}>
        <div className="aggree-consent mb-32">
          <p className="text-center">
            <div> By creating a l1feID, you agree to our </div>{" "}
            <span className="terms-color">Terms of Service </span> and{" "}
            <span className="terms-color">Privacy & Cookie Statement.</span>
          </p>
        </div>

        <img className="bottom-img" src={american} alt="american_icon" />
      </Step4>
    );
  } else if (step === 5) {
    return (
      <Step5 setStep={setStep}>
        <div className="aggree-consent mb-32">
          <p className="text-center">
            <div> By creating a l1feID, you agree to our </div>{" "}
            <span className="terms-color">Terms of Service </span> and{" "}
            <span className="terms-color">Privacy & Cookie Statement.</span>
          </p>
        </div>

        <img className="bottom-img" src={american} alt="american_icon" />
      </Step5>
    );
  }
};

export default SignUp;
