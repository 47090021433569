import React, { useState } from "react";
import { Button } from "react-bootstrap";
import instance from "../../../utils/axios";
import { API } from "../../../utils/apiendpoints";
import { useLocation } from "react-router-dom";

const Step3 = ({ setStep, children }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [loader, setLoader] = useState(false);
  const [verifyOption, setVerifyOption] = useState("email");

  const selectedOption = (e) => {
    setVerifyOption(e.target.value);
  };

  const sendCode = () => {
    const userEmail = queryParams.get("email");
    if (verifyOption === "email") {
      const params = {
        email: userEmail,
      };
      setLoader(true);
      instance
        .post(API.emailVerification, params)
        .then((res) => {
          setLoader(false);
          if (!res?.error) {
            setStep((prev) => prev + 1);
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    }
  };
  return (
    <>
      <div className="verifi-method text-center">
        <div className="w-50 mx-auto">
          {/* <div className="text-center mb-24 d-flex align-items-center justify-content-center">
            <input type="radio" name="sendcode" checked />{" "}
            <label className="notify-para ml-15" onClick={selectedOption}>
              Phone number
            </label>
          </div> */}
          <div className="text-center d-flex align-items-center justify-content-center">
            <input type="radio" name="sendcode" checked/>{" "}
            <label className="notify-para ml-15" onClick={selectedOption}>
              Email address
            </label>
          </div>
        </div>
        <Button
          className="custom-btn mt-24 mb-24"
          variant="primary"
          disabled={loader}
          // onClick={() => setStep((prev) => prev + 1)}
          onClick={() => sendCode()}
        >
          {loader ? (
            <span
              className="spinner-border me-2 spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            "Send Code"
          )}
        </Button>
        {children}
      </div>
    </>
  );
};

export default Step3;
