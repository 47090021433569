import axios from "axios";
import { toast } from "react-toastify";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = `bearer ${token.replace('"', "")}`;
    } else {
      const tempToken = localStorage.getItem("tempToken");
      if (tempToken) {
        config.headers.Authorization = `bearer ${tempToken.replaceAll(
          '"',
          ""
        )}`;
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  (error) => {
    if (
      !(
        window.location.pathname.includes("/signup") &&
        error?.response?.data?.message.includes("User does not exist")
      )
    ) {
      toast.error(` ${error?.response?.data?.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    return Promise.reject(error);
  }
);

export default instance;
