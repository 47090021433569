import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Step5 = ({ setStep, children }) => {
  const navigate = useNavigate();

  return (
    <div className="verify-otp text-center final-step">
      <div className="inner-block mt-32 mb-24">
        <p className="notify-para">
          Thank you for creating your lifeID and your interest in dSocial
        </p>
        <p className="notify-para">
          We’re in the process of launching dSocial and will notify you when
          the official ALPHA version of the app launches publicly.
        </p>
        <p className="notify-para">
          In the meantime, check out our L1FE dashboard where you can manage
          your L1FE membership.
        </p>
      </div>
      <Button
        className="custom-btn  mb-24"
        variant="primary"
        // disabled={true}
        // onClick={() => setStep((prev) => prev + 1)}
        onClick={() => navigate('/dashboard')}
      >
        Got to L1FE Dashboard

      </Button>
      {children}
    </div>
  );
};

export default Step5;
