import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import life from "./../../assets/icons/lifeGroup.png";

import dSocialBack from "./../../assets/icons/dSocialBack.png";
import lifeMistry from "./../../assets/images/lifeMistry.png";

import american from "./../../assets/images/americanMade.png";
import Step5 from "../../components/signup/steps/Step5";

const SignUp = () => {
  const [step, setStep] = useState(1);
  const [active, setActive] = useState("login"); // signup

  const toggleAuth = (state) => {
    setActive(state);
  };
  return (
    <Container fluid className="vh-100 login-wrapper wrapper">
      <Row className="h-100">
        <Col xl={4} lg={5} md={6} className="left-col d-none d-md-block">
          <div className="w-50 mx-auto text-center left-inner-block d-flex flex-column align-items-center justify-content-end h-100">
            <img className="img-social-back" src={dSocialBack} alt="back" />
            <h1>Here is your voice back</h1>
            <div className="mistry-block">
              <img src={lifeMistry} alt="life_mistry" />
            </div>
          </div>
        </Col>
        <Col xl={8} lg={7} md={6} className="right-col">
          <Container className="h-100">
            <Row className="h-100">
              <Col lg={{ span: 6, offset: 3 }} className="auth-block">
                <div className="w-100">
                  <div className="icon-block">
                    <img src={life} alt="life" />
                  </div>

                  <Step5 setStep={setStep}>
                    <div className="aggree-consent mb-32">
                      <p className="text-center">
                        <div> By creating a l1feID, you agree to our </div>{" "}
                        <span className="terms-color">Terms of Service </span>{" "}
                        and{" "}
                        <span className="terms-color">
                          Privacy & Cookie Statement.
                        </span>
                      </p>
                    </div>

                    <img
                      className="bottom-img"
                      src={american}
                      alt="american_icon"
                    />
                  </Step5>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUp;
