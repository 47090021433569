import React from "react";
import { useNavigate } from "react-router-dom";

import { Container, Row, Col, Button } from "react-bootstrap";
import dSocial from "./../assets/icons/dSocial.png";
import mobile from "./../assets/images/imageMobile.png";
import american from "./../assets/images/americanMade.png";
import dSocialBack from "./../assets/icons/dSocialBack.png";
import lifeMistry from "./../assets/images/lifeMistry.png";

const Home = () => {
  const navigate = useNavigate();

  return (
    <>
      <Container fluid className="vh-100 wrapper">
        <Row className="h-100">
          <Col xl={4} lg={5} md={6} className="left-col d-none d-md-block">
            <div className="w-50 mx-auto text-center left-inner-block d-flex flex-column align-items-center justify-content-end h-100">
              {/* <img className="img-social-back" src={dSocialBack} alt="back" /> */}
              <h1>The social network they can’t control</h1>
              <div className="mistry-block">
                <img src={lifeMistry} alt="life_mistry" />
              </div>
            </div>
          </Col>
          <Col xl={8} lg={7} md={6} sm={12} className="right-col">
            <Container className="h-100">
              <Row className="h-100">
                <Col xl={8} lg={12} className="left-col-child">
                  <div>
                    <img src={dSocial} alt="site_icon" />
                    <h1>socialize without censorship</h1>
                    <p className="mt-4 ">
                      <span className="text-black">Introducing dSocial</span>, a
                      completely decentralized social network powered by its
                      users. Say goodbye to cancel culture.
                    </p>
                    <Button
                      className="custom-btn"
                      variant="primary"
                      onClick={() => navigate("/login")}
                    >
                      Signup For Early Access
                    </Button>
                    <img src={american} alt="american_icon" />
                  </div>
                </Col>
                <Col xl={4} className="right-col-child d-none d-xl-flex">
                  <img src={mobile} alt="mobile" />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
