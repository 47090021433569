import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Input from "../../shared/Input";
import { useForm } from "react-hook-form";
import emailIcon from "./../../../assets/icons/emailIcon.png";
import eyeIcon from "./../../../assets/icons/eyeIcon.png";
import PasswordInput from "../../shared/PasswordInput";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import instance from "../../../utils/axios";
import { API } from "../../../utils/apiendpoints";
import { registerUser } from "../../../store/registeruser";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const Step2 = ({ setStep, children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();

  const [countries, setCountries] = useState([]);

  const { loader, userInfo } = useSelector((store) => store.registerReducer);

  const {
    register,
    handleSubmit,
    trigger,
    setError,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange", reValidateMode: "onChange" });

  const onFormSubmit = (data) => {
    trigger()
      .then((res) => {
        if (res) {
          const email = queryParams.get("email");
          data.email = email;
          delete data.confirmPassword;

          dispatch(registerUser(data))
            .then((res) => {
              if (!res?.error) {
                setStep((prev) => prev + 1);
              }
            })
            .catch((error) => { });
        }
      })
      .catch((err) => console.log(err));
  };

  const getCountries = async () => {
    await instance.get(API.getCountries).then((res) => {
      if (!res?.error) {
        setCountries(res?.data);
      }
    });
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onFormSubmit)} className="form-fields mt-32">
        <div className="common-input mb-5 mt-32 position-relative">
          <select
            aria-label="Default select example"
            {...register("country", {
              required: true,
              validate: (value) => {
                return (
                  value !== "Select Country" ||
                  // "This is A Required Field"
                  "Please select a country"
                );
              },
            })}
            className={`form-select ${errors?.country ? "error-border" : "normal-border"
              }`}
          >
            <option defaultValue hidden>
              Select Country
            </option>
            {countries?.map((ele) => {
              return (
                <option key={ele} value={ele}>
                  {ele}
                </option>
              );
            })}
          </select>
          {errors?.country && (
            <p className={`error-text`}>Please select a country</p>
          )}
        </div>
        <div className="w-100 mb-5 mt-32">
          <PasswordInput
            name="password"
            placeholder="Enter Your Password"
            icon={eyeIcon}
            errors={errors}
            register={register("password", {
              required: {
                value: true,
                message: "This is required",
              },
              pattern: {
                value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_\-\+=:;"'?\/>.<,{}\[\]])[a-zA-Z\d~`!@#$%^&*()_\-\+=:;"'?\/>.<,{}\[\]]{8,}$/,
                message:
                  "Password must be 8 characters and must contain atleast 1 small alphabet, 1 capital alphabet, 1 numeric value and 1 special character",
              },
              minLength: {
                value: 8,
                message: "Password should contain minimum 8 characters ",
              },
              maxLength: {
                value: 20,
                message: "Password should contain maximum 20 characters ",
              },
              validate: (value) => {
                if (value === getValues()?.confirmPassword) {
                  setError("confirmPassword", "");
                }
              },
            })}
          />
        </div>
        <div className="w-100 mb-5 mt-32">
          <PasswordInput
            name="confirmPassword"
            placeholder="Confirm password"
            icon={eyeIcon}
            errors={errors}
            register={register("confirmPassword", {
              required: {
                value: true,
                message: "This is required",
              },
              validate: (value) =>
                value === watch("password") || "Passwords do not match.",
              pattern: {
                value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_\-\+=:;"'?\/>.<,{}\[\]])[a-zA-Z\d~`!@#$%^&*()_\-\+=:;"'?\/>.<,{}\[\]]{8,}$/,
                message:
                  "Password must be 8 characters and must contain atleast 1 small alphabet, 1 capital alphabet, 1 numeric value and 1 special character",
              },
              minLength: {
                value: 8,
                message: "password should contain minimum 8 characters ",
              },
              maxLength: {
                value: 20,
                message: "password should contain maximum 20 characters ",
              },
            })}
          />
        </div>

        <Button
          className="custom-btn mb-24"
          variant="primary"
          type="submit"
        >
          {loader ? (
            <>
              <span
                className="spinner-border me-2 spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </>
          ) : (
            "Next"
          )}
        </Button>

        {children}
      </form>
    </>
  );
};

export default Step2;
