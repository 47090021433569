import { Outlet, Navigate } from 'react-router-dom';

const ProtectedRoutes = ({ isAuthenticated, isPublic }) => {
    const auth = isAuthenticated();

    return (
        (isPublic && !auth) || (!isPublic && auth) ? (
            <Outlet />
        ) : (
            <Navigate to={isPublic ? '/dashboard' : '/login'} />
        )
    );
};

export default ProtectedRoutes;
